[data-affiliates-carousel] {
  .app {
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
    font-size: 1.2em;
    margin-bottom: 15px;
    padding-top: 1px;
    background-color: white;
  }

  .disableAllPointerEvents {
    pointer-events: none;
  }

  [data-colour-theme='amazon'] {
    --amazon-orange: #f90;
    --amazon-ink: #232f3e;
  }
}
